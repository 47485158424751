<template>
  <div>
    <section-banner v-for="(section, index) in sections" :key="index" :model="section"></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: 'Compliance',
  components: {
    SectionBanner
  },
  data() {
    return {
      sections: [
        {
          Name: "What we do",
          Title: "Compliance",
          Subtitle: "Abiding by the rules",
          Description: "Every business needs to stay up to date with all relevant laws, policies, and regulations that may apply, and where necessary, taking steps to adapt and conform to them.",
          img: require("@/assets/img/Natalie.jpg")
        },
        {
          TextColumn: true,
          TextColumn1: `<p>Our priority is making sure you’re in step with current and future guidelines, freeing you to concentrate on the real work at hand – running your business. <br></p>
            <p>We can help you stay on the right side of the line, by advising and assisting, in:<br></p>
            <ul>
              <li>The Financial Sector</li>
              <li>Privacy (POPI)</li>
              <li>Data Protection</li>
              <li>Corporate Governance</li>
              <li>Consumer Protection Act</li>
              <li>Credit (NCA)</li>
            </ul>`,
            TextColumn2: false 
        },
        {
          Profiles: true,
          Name: "Talk to Us",
            Bios: [
            {
              Title: "Natalie Lubbe",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/natalielubbe",
              Email: "natalie@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Natalie-bio.jpg"),
              left: true,
              last: true
            }
          ],
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

</style>